import { Node } from "@intentsify/types";
import { TreeFilter } from "components";
import { useBusinessEventsTree } from "utils/useBusinessEventsTree";
import { useBusinessEvents } from "../hooks/useBusinessEvents";

export const BusinessEventsFilter = ({
  value,
  onChange,
}: {
  value: Node[];
  onChange: (value: Node[]) => void;
}) => {
  const { data, isLoading } = useBusinessEvents();
  const businessEventsTree = useBusinessEventsTree(data?.businessEvents);

  return (
    <TreeFilter
      expandOnInitialRender
      subject="Business Events"
      isLoading={isLoading}
      isDisabled={isLoading}
      nodes={businessEventsTree}
      included={value}
      onIncludedChange={onChange}
    />
  );
};
