import {
  CampaignKeywordsAndTopicsDTO,
  FirmographicsFiltersDTO,
} from "@intentsify/dto";
import {
  Campaign,
  CampaignAdvancedSettings,
  CampaignSettings,
} from "@intentsify/types";
import isEqual from "lodash/isEqual";
import { DateTime } from "luxon";

export const haveSettingsChanged = (
  oldSettings: CampaignSettings,
  newSettings: CampaignSettings
) => {
  return (
    oldSettings.displayName !== newSettings.displayName ||
    oldSettings.notes !== newSettings.notes ||
    DateTime.fromISO(oldSettings.startDate).startOf("day").toISO() !==
      DateTime.fromISO(newSettings.startDate).startOf("day").toISO() ||
    (oldSettings.endDate === null && newSettings.endDate !== null) ||
    (oldSettings.endDate !== null &&
      newSettings.endDate !== null &&
      DateTime.fromISO(oldSettings.endDate).startOf("day").toISO() !==
        DateTime.fromISO(newSettings.endDate).startOf("day").toISO()) ||
    oldSettings.corporateEntityId !== newSettings.corporateEntityId ||
    oldSettings.companyId !== newSettings.companyId ||
    oldSettings.companyType !== newSettings.companyType ||
    oldSettings.repId !== newSettings.repId ||
    oldSettings.customerSuccessManagerId !==
      newSettings.customerSuccessManagerId ||
    oldSettings.countries
      .map(({ countryId }) => countryId)
      .sort()
      .join() !==
      newSettings.countries
        .map(({ countryId }) => countryId)
        .sort()
        .join() ||
    oldSettings.businessUnits
      .map(({ businessUnitId }) => businessUnitId)
      .sort()
      .join() !==
      newSettings.businessUnits
        .map(({ businessUnitId }) => businessUnitId)
        .sort()
        .join()
  );
};

export const haveDomainsSettingsChanged = (
  oldFirmoFilters: FirmographicsFiltersDTO | undefined,
  newFirmoFilters: FirmographicsFiltersDTO | undefined,
  haveFilesChanged: boolean,
  oldDomainEnhancementStrategy?: Campaign["accounts"]["domainEnhancementStrategy"],
  newDomainEnhancementStrategy?: Campaign["accounts"]["domainEnhancementStrategy"]
) => {
  const firmoFiltersChanged = !isEqual(oldFirmoFilters, newFirmoFilters);

  return (
    firmoFiltersChanged ||
    haveFilesChanged ||
    oldDomainEnhancementStrategy !== newDomainEnhancementStrategy
  );
};

export const haveKeywordsAndTopicsChanged = (
  oldKeywordsAndTopics: CampaignKeywordsAndTopicsDTO,
  newKeywordsAndTopics: CampaignKeywordsAndTopicsDTO
) => {
  return (
    oldKeywordsAndTopics.keywords
      .map((k) => `${k.keyword}_${k.source}`)
      .sort()
      .join() !==
      newKeywordsAndTopics.keywords
        .map((k) => `${k.keyword}_${k.source}`)
        .sort()
        .join() ||
    oldKeywordsAndTopics.topics
      .map((t) => `${t.topicId}_${t.source}`)
      .sort()
      .join() !==
      newKeywordsAndTopics.topics
        .map((t) => `${t.topicId}_${t.source}`)
        .sort()
        .join()
  );
};

export const haveAdvancedSettingsChanged = (
  oldAdvancedSettings: Omit<CampaignAdvancedSettings, "convertrCampaigns">,
  newAdvancedSettings: Omit<CampaignAdvancedSettings, "convertrCampaigns">
) => {
  return (
    oldAdvancedSettings.pixelIds.sort().join() !==
      newAdvancedSettings.pixelIds.sort().join() ||
    oldAdvancedSettings.beeswaxCampaignIds.sort().join() !==
      newAdvancedSettings.beeswaxCampaignIds.sort().join() ||
    newAdvancedSettings.automatedIntentDeliveryEnabled !==
      oldAdvancedSettings.automatedIntentDeliveryEnabled ||
    oldAdvancedSettings.deliveryRecipients
      .map((i) => i.email)
      .sort()
      .join() !==
      newAdvancedSettings.deliveryRecipients
        .map((i) => i.email)
        .sort()
        .join() ||
    oldAdvancedSettings.leadDomainCap !== newAdvancedSettings.leadDomainCap ||
    newAdvancedSettings.automatedBuyingGroupDeliveryEnabled !==
      oldAdvancedSettings.automatedBuyingGroupDeliveryEnabled ||
    oldAdvancedSettings.leadDomainCap !== newAdvancedSettings.leadDomainCap ||
    oldAdvancedSettings.showBuyingGroupContactDetails !==
      newAdvancedSettings.showBuyingGroupContactDetails ||
    oldAdvancedSettings.averageDealSize !==
      newAdvancedSettings.averageDealSize ||
    oldAdvancedSettings.averageSalesCycleMonths !==
      newAdvancedSettings.averageSalesCycleMonths
  );
};

export enum PacingProgressColor {
  green = "rgba(110, 179, 25, 0.25)",
  yellow = "rgba(217, 181, 0, 0.25)",
  red = "rgba(255, 4, 4, 0.25)",
}

const COLOR_SCHEMES_PACING_BREAKPOINTS = [
  { colorScheme: PacingProgressColor.green, min: (2 * 100) / 3 },
  { colorScheme: PacingProgressColor.yellow, min: 100 / 3 },
  { colorScheme: PacingProgressColor.red, min: 0 },
];

export const getColorSchemeBasedOnProgress = (value: number): string => {
  return (
    COLOR_SCHEMES_PACING_BREAKPOINTS.find(
      (colorScheme) => value >= colorScheme.min
    )?.colorScheme || PacingProgressColor.red
  );
};

export enum SparklineChartColor {
  green = "#6EB319",
  yellow = "#D9B500",
  red = "#FF0404",
}

export const getSparklineChartColor = (num1: number, num2: number) => {
  const percentageDifference = ((num2 - num1) / num1) * 100;

  if (percentageDifference > 5) {
    return SparklineChartColor.green;
  } else if (percentageDifference < -5) {
    return SparklineChartColor.red;
  } else {
    return SparklineChartColor.yellow;
  }
};
