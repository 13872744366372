import { Flex, Icon, useColorModeValue } from "@chakra-ui/react";
import { Endpoints } from "api";
import { Badge, IconButton } from "components";
import { AiOutlineDownload } from "react-icons/ai";
import { FaFileArchive } from "react-icons/fa";
import { useTrackCampaignFileDownloaded } from "tracking/useTrackCampaignFileDownloaded";
import { useDownloadFile } from "utils";

type AutodiscoveryMetadataDownloadProps = {
  campaignId: number;
};

export const AutodiscoveryMetadataDownload = ({
  campaignId,
}: AutodiscoveryMetadataDownloadProps) => {
  const { download } = useDownloadFile();
  const csvIconColor = useColorModeValue("gray.600", "gray.200");

  const badgeFileName = `autodiscovery metadata (${campaignId}).zip`;
  const trackCampaignFileDownloaded = useTrackCampaignFileDownloaded();

  const handleMetadataDownload = async () => {
    await download({
      url: Endpoints.Campaigns.Get.Autodiscovery.DownloadMetadata(campaignId),
      fileName: `intent_model_${campaignId}_autodiscovery_metadata_${new Date()
        .toISOString()
        .slice(0, 10)}`,
      fileExtension: "zip",
    });
    trackCampaignFileDownloaded({
      type: "campaign autodiscovery processed metadata",
      campaignIds: [Number(campaignId)],
    });
  };

  return (
    <Flex alignItems="center">
      <Icon color={csvIconColor} w={4} h={4} mr={2} as={FaFileArchive} />
      <Badge mr={2}>{badgeFileName}</Badge>
      <IconButton
        variant="solid"
        tooltip="Download Processed Autodiscovery Metadata"
        size="xs"
        icon={<AiOutlineDownload />}
        onClick={() => {
          handleMetadataDownload();
        }}
      />
    </Flex>
  );
};
