import { useToast } from "@chakra-ui/react";
import { BusinessDataAppendDTO, TalToolFileRO } from "@intentsify/dto";
import { TalToolsTypes } from "@intentsify/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Endpoints, apiService } from "api";
import {
  mapTalToolFile,
  talPreparationToolsFilesKey,
} from "../../../hooks/useTalPreparationToolsFiles";

export const useBusinessDataAppend = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (BusinessDataAppendDTO: BusinessDataAppendDTO) =>
      apiService
        .post<TalToolFileRO>(
          Endpoints.TalTools.BusinessDataAppend.Post,
          BusinessDataAppendDTO
        )
        .then((response) => mapTalToolFile(response.data)),
    onSuccess: (processingFile) => {
      const data = queryClient.getQueryData<TalToolFileRO[]>(
        talPreparationToolsFilesKey(TalToolsTypes.BUSINESS_DATA_APPEND)
      );

      if (!data) {
        return;
      }

      queryClient.setQueryData(
        talPreparationToolsFilesKey(TalToolsTypes.BUSINESS_DATA_APPEND),
        data.map((file) =>
          file.fileName === processingFile.fileName ? processingFile : file
        )
      );
    },
    onError: () => {
      toast({
        status: "error",
        description:
          "Requesting Business Data Append failed. Please try again.",
        duration: null,
        isClosable: true,
      });
    },
  });
};
