import { InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  HStack,
  ListItem,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  SimpleGrid,
  Switch,
  Text,
  Tooltip,
  UnorderedList,
  chakra,
} from "@chakra-ui/react";
import {
  HasAccess,
  useHasAccessTo,
} from "@intentsify/authorization/dist/react";
import {
  CampaignAdvancedSettings,
  CampaignDeliveryRecipientType,
} from "@intentsify/types";
import { isPopulatedArray } from "@intentsify/utils";
import { useQuery } from "@tanstack/react-query";
import { DynamicInput, Filter, useDynamicInput } from "components";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { useRecoilState, useRecoilValue } from "recoil";
import { MutateCampaignAdvancedSettingsParams } from "screens/Campaigns/screens/CampaignsWizard/CampaignsWizard.requests";
import { useComponentColors } from "theme";
import { WithCampaignId } from "types";
import { NavigationBlockers } from "../../NavigationBlockers/NavigationBlockers";
import { SubmitTrigger } from "../../SettingsStep/SettingsForm";
import { getCampaignPixelIds } from "../ActivationSettingsStep.requests";
import {
  isLoadingAdvancedSettingsSelector,
  isLoadingPixelIdsAtom,
} from "../ActivationSettingsStep.state";
import { CampaignActivationSettingsInputs } from "../ActivationSettingsStep.types";
import { useWatchActivationSettings } from "../ActivationSettingsStep.utils";
import { AutomatedIntentDelivery } from "./AutomatedIntentDelivery/AutomatedIntentDelivery";
import { BuyingGroupContactsDelivery } from "./BuyingGroupContactsDelivery/BuyingGroupContactsDelivery";

type ActivationSettingsFormProps = {
  id: number;
  campaignAdvancedSettings: CampaignAdvancedSettings;
  onSubmit: (params: MutateCampaignAdvancedSettingsParams) => void;
};

const ActivationSettingsForm = forwardRef<
  SubmitTrigger | undefined,
  ActivationSettingsFormProps
>(({ id, campaignAdvancedSettings, onSubmit }, ref) => {
  const componentColors = useComponentColors();

  const [intentDataDeliveryRecipients, setIntentDataDeliveryRecipients] =
    useState<string[]>(
      campaignAdvancedSettings.deliveryRecipients
        .filter((e) => e.type === CampaignDeliveryRecipientType.Intent)
        .map((e: { email: string }) => e.email)
    );

  const [hasAutomatedIntentDelivery, setHasAutomatedIntentDelivery] = useState(
    campaignAdvancedSettings.automatedIntentDeliveryEnabled
  );

  const [showBuyingGroupContactDetails, setShowBuyingGroupContactDetails] =
    useState(campaignAdvancedSettings.showBuyingGroupContactDetails);

  const [buyingGroupDeliveryRecipients, setBuyingGroupDeliveryRecipients] =
    useState<string[]>(
      campaignAdvancedSettings.deliveryRecipients
        .filter((e) => e.type === CampaignDeliveryRecipientType.BuyingGroup)
        .map((e: { email: string }) => e.email)
    );

  const [hasAutomatedBuyingGroupelivery, setHasAutomatedBuyingGroupelivery] =
    useState(campaignAdvancedSettings.automatedBuyingGroupDeliveryEnabled);

  const [isLoadingPixelIds, setIsLoadingPixelIds] = useRecoilState(
    isLoadingPixelIdsAtom
  );
  const isLoading = useRecoilValue(isLoadingAdvancedSettingsSelector);

  const hasAccessToIntentDataDelivery = useHasAccessTo(
    "campaign.intentDataDelivery"
  );

  const hasAccessToBuyingGroup = useHasAccessTo("buyingGroup");

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<CampaignActivationSettingsInputs>({
    mode: "onBlur",
    defaultValues: {
      pixelIds: campaignAdvancedSettings.pixelIds,
      leadDomainCap: campaignAdvancedSettings.leadDomainCap,
      averageDealSize: campaignAdvancedSettings.averageDealSize,
      averageSalesCycleMonths: campaignAdvancedSettings.averageSalesCycleMonths,
    },
  });

  const leadDomainCap = watch("leadDomainCap");
  const selectedPixelIds = watch("pixelIds");
  const averageDealSize = watch("averageDealSize");
  const averageSalesCycleMonths = watch("averageSalesCycleMonths");

  const pixelIds = useQuery(["getCampaignPixelIds"], getCampaignPixelIds, {
    staleTime: 0,
  });

  const {
    items: beeswaxIds,
    addItem: addBeeswaxId,
    removeItem: removeBeeswaxId,
  } = useDynamicInput(campaignAdvancedSettings.beeswaxCampaignIds || []);

  useEffect(() => {
    setIsLoadingPixelIds(pixelIds.isLoading);
  }, [pixelIds.isLoading, setIsLoadingPixelIds]);

  const newCampaignAdvancedSettings: CampaignAdvancedSettings = useMemo(() => {
    return {
      automatedIntentDeliveryEnabled: hasAutomatedIntentDelivery,
      automatedBuyingGroupDeliveryEnabled: hasAutomatedBuyingGroupelivery,
      deliveryRecipients: [
        ...intentDataDeliveryRecipients.map((e) => ({
          email: e,
          type: CampaignDeliveryRecipientType.Intent,
        })),
        ...buyingGroupDeliveryRecipients.map((e) => ({
          email: e,
          type: CampaignDeliveryRecipientType.BuyingGroup,
        })),
      ],
      beeswaxCampaignIds: beeswaxIds.map(Number),
      convertrCampaigns: [],
      pixelIds: selectedPixelIds,
      leadDomainCap,
      showBuyingGroupContactDetails,
      averageDealSize,
      averageSalesCycleMonths,
    };
  }, [
    showBuyingGroupContactDetails,
    beeswaxIds,
    buyingGroupDeliveryRecipients,
    hasAutomatedBuyingGroupelivery,
    hasAutomatedIntentDelivery,
    intentDataDeliveryRecipients,
    leadDomainCap,
    selectedPixelIds,
    averageDealSize,
    averageSalesCycleMonths,
  ]);

  useImperativeHandle(ref, () => ({
    submit({ shouldExitOnSuccess }) {
      handleSubmit((values) => {
        const newSettings: WithCampaignId<
          Omit<CampaignAdvancedSettings, "convertrCampaigns">
        > = { id: id, ...newCampaignAdvancedSettings, ...values };

        onSubmit({
          newSettings,
          shouldExitOnSuccess,
        });
      })();
    },
  }));

  const haveChanged = useWatchActivationSettings(
    campaignAdvancedSettings,
    newCampaignAdvancedSettings
  );

  return (
    <>
      <NavigationBlockers
        isUnsavedChangesBlockerEnabled={haveChanged && !isLoading}
      />

      <SimpleGrid>
        <GridItem colSpan={{ md: 2 }}>
          <chakra.form method="POST" borderTopRadius={4}>
            <HStack spacing={2}>
              <Text
                fontSize="md"
                fontWeight="bold"
                color={componentColors.form.formLabelColor}
              >
                Content Syndication Programs
                <Tooltip
                  aria-label={"automated-intent-delivery-tooltip"}
                  placement={"top-end"}
                  label={
                    <>
                      These campaigns are pulled from the lead validator
                      platform, Convertr, and cannot be edited within this
                      screen. If changes are needed, please reach out to your
                      customer success manager.
                    </>
                  }
                >
                  <InfoIcon w={4} h={4} verticalAlign={"center"} pl={1} />
                </Tooltip>
              </Text>
            </HStack>

            <Text
              fontSize="sm"
              fontWeight="md"
              color={componentColors.form.formLabelColor}
              pb={2}
            >
              Below is a list of connected Convertr campaign IDs as well as an
              option to enter recipient emails for automated intent data
              delivery that correspond with their live content syndication
              programs.
            </Text>

            {isPopulatedArray(campaignAdvancedSettings.convertrCampaigns) ? (
              <UnorderedList fontSize="sm" pl={4}>
                {campaignAdvancedSettings.convertrCampaigns.map((i) => (
                  <ListItem key={i.convertrCampaignId}>
                    <Text
                      fontSize="sm"
                      fontWeight="md"
                      color={componentColors.form.formLabelColor}
                    >
                      {" "}
                      {i.displayName} ({i.convertrCampaignId}) — leads required:{" "}
                      <b>{i.leadsRequired}</b>
                    </Text>
                  </ListItem>
                ))}
              </UnorderedList>
            ) : (
              <Box>
                <Text fontSize="sm">Not available</Text>
              </Box>
            )}

            <FormControl
              isInvalid={!!errors?.leadDomainCap?.message}
              isRequired
              mt={4}
            >
              <FormLabel
                fontSize="sm"
                fontWeight="md"
                color={componentColors.form.formLabelColor}
              >
                Lead Domain Cap
              </FormLabel>
              <NumberInput
                shadow="sm"
                size="sm"
                rounded="md"
                width="2xs"
                defaultValue={10}
                max={10}
                min={1}
                step={1}
              >
                <NumberInputField
                  {...register("leadDomainCap", { valueAsNumber: true })}
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>

              <FormErrorMessage>
                {errors?.leadDomainCap?.message}
              </FormErrorMessage>
            </FormControl>

            {hasAccessToIntentDataDelivery && (
              <>
                <Divider my={8} />
                <FormControl mt={8} as={GridItem} maxW="50%" pr={2}>
                  <AutomatedIntentDelivery
                    automatedIntentDelivery={hasAutomatedIntentDelivery}
                    emails={intentDataDeliveryRecipients}
                    onEmailsChange={(emails) =>
                      setIntentDataDeliveryRecipients(emails)
                    }
                    onSwitchChange={(isChecked) =>
                      setHasAutomatedIntentDelivery(isChecked)
                    }
                  />
                </FormControl>
              </>
            )}

            <HasAccess to="campaign.editShowBuyingGroupContactDetails">
              <Divider my={8} />
              <FormControl mt={8} as={GridItem} maxW="50%" pr={2}>
                <HStack spacing={2}>
                  <Text
                    p="0"
                    m="0"
                    fontSize="md"
                    fontWeight="bold"
                    color={componentColors.form.formLabelColor}
                  >
                    Show buying group contact details
                    <Tooltip
                      aria-label={"automated-intent-delivery-tooltip"}
                      placement={"top-end"}
                      label={
                        <>
                          When enabled, this setting will display contact
                          details of the buying group insights in the accounts
                          section.
                        </>
                      }
                    >
                      <InfoIcon w={4} h={4} verticalAlign={"center"} pl={1} />
                    </Tooltip>
                  </Text>

                  <Switch
                    isChecked={showBuyingGroupContactDetails}
                    onChange={(e) =>
                      setShowBuyingGroupContactDetails(e.currentTarget.checked)
                    }
                  />
                </HStack>
              </FormControl>
            </HasAccess>

            <Divider my={8} />

            <HasAccess to="campaign.moatReport">
              <FormControl
                isInvalid={!!errors?.averageDealSize?.message}
                mt={4}
              >
                <FormLabel
                  fontSize="sm"
                  fontWeight="md"
                  color={componentColors.form.formLabelColor}
                >
                  Average Deal Size
                </FormLabel>
                <NumberInput
                  shadow="sm"
                  size="sm"
                  rounded="md"
                  width="2xs"
                  max={1_000_000_000_000}
                  min={1}
                  step={100}
                >
                  <NumberInputField
                    {...register("averageDealSize", { valueAsNumber: true })}
                  />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>

                <FormErrorMessage>
                  {errors?.averageDealSize?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={!!errors?.averageSalesCycleMonths?.message}
                mt={4}
              >
                <FormLabel
                  fontSize="sm"
                  fontWeight="md"
                  color={componentColors.form.formLabelColor}
                >
                  Averages Sales Cycle In Months
                </FormLabel>
                <NumberInput
                  shadow="sm"
                  size="sm"
                  rounded="md"
                  width="2xs"
                  max={12}
                  min={1}
                  step={1}
                >
                  <NumberInputField
                    {...register("averageSalesCycleMonths", {
                      valueAsNumber: true,
                    })}
                  />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>

                <FormErrorMessage>
                  {errors?.averageSalesCycleMonths?.message}
                </FormErrorMessage>
              </FormControl>

              <Divider my={8} />
            </HasAccess>

            <Flex
              mt={2}
              gap={4}
              alignItems="flex-end"
              justifyContent="space-between"
            >
              <FormControl as={GridItem} w="100%">
                <DynamicInput
                  label="Beeswax Campaign ID"
                  items={beeswaxIds}
                  addItem={addBeeswaxId}
                  removeItem={removeBeeswaxId}
                  formProps={{ mt: 0, w: "100%" }}
                  containerProps={{ pb: 0, w: "100%" }}
                />
              </FormControl>
              <FormControl
                as={GridItem}
                colSpan={[0, 3]}
                isInvalid={!!errors?.pixelIds}
                w="100%"
              >
                <Controller
                  name="pixelIds"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Filter
                      selected={
                        pixelIds.data &&
                        value &&
                        pixelIds.data.filter((p) => value.includes(p.label))
                      }
                      label="Pixel ID"
                      isMulti
                      isLoading={isLoadingPixelIds}
                      options={pixelIds.data ?? []}
                      placeholder="Select pixel ID"
                      onFilterValuesChange={(option) => {
                        onChange(option.map((s) => s.label));
                      }}
                    />
                  )}
                />
              </FormControl>
            </Flex>

            {hasAccessToBuyingGroup && (
              <>
                <Divider my={8} />
                <FormControl as={GridItem} maxW="50%" pr={2}>
                  <BuyingGroupContactsDelivery
                    recipients={buyingGroupDeliveryRecipients}
                    onRecipientsChange={setBuyingGroupDeliveryRecipients}
                    isEnabled={hasAutomatedBuyingGroupelivery}
                    onIsEnabledChange={setHasAutomatedBuyingGroupelivery}
                  />
                </FormControl>
              </>
            )}
          </chakra.form>
        </GridItem>
      </SimpleGrid>
    </>
  );
});

export { ActivationSettingsForm };
