import { Box, Flex } from "@chakra-ui/react";
import { SplitBy } from "@intentsify/types";
import { getDatesFromDateRange, isPopulatedArray } from "@intentsify/utils";
import { useQuery } from "@tanstack/react-query";
import { Timeseries, ViewContainer, ViewSelector } from "components";
import { useMemo } from "react";
import { AiOutlineLineChart, AiOutlineTable } from "react-icons/ai";
import { useParams } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { ViewEnum } from "screens/IntentAnalysis/screens/CampaignsTrendingSignals/CampaignsTrendingSignals.types";
import {
  ChartExport,
  useCampaignMeta,
  useExportableChart,
} from "shared/components";
import { timeseriesToCsvData } from "utils";
import { Filters } from "./components";
import { TrendingTopicsTable } from "./components/TrendingTopicsTable";
import {
  getMostActiveTopics,
  getMostActiveTopicsTimeseries,
} from "./TrendingTopics.requests";
import {
  selectedTrendingTopicsAtom,
  topicsViewAtom,
  trendingTopicsOptionsStateAtom,
  trendingTopicsStateSelector,
} from "./TrendingTopics.state";

const title = "Trending Topics";

const TrendingTopics = () => {
  const campaignId = Number(useParams<"campaignId">().campaignId);
  const {
    isLoading,
    selectedView,
    selectedDateRange,
    selectedSplitBy,
    selectedTopics,
  } = useRecoilValue(trendingTopicsStateSelector);

  const { campaignMeta: meta } = useCampaignMeta({ campaignId });

  const setSelectedTopics = useSetRecoilState(selectedTrendingTopicsAtom);
  const setOptions = useSetRecoilState(trendingTopicsOptionsStateAtom);
  const setSelectedView = useSetRecoilState(topicsViewAtom);

  useQuery(
    ["trendingTopicsOptions", campaignId, selectedDateRange],
    () => {
      if (campaignId) {
        return getMostActiveTopics(
          campaignId,
          selectedDateRange,
          SplitBy.Nothing
        );
      }
    },
    {
      onSuccess: (data) => {
        if (data) {
          setOptions({ isLoading: false, ...data });

          setSelectedTopics({
            campaignId,
            data: data.topics.slice(0, 5),
          });
        }
      },
    }
  );

  const { data, isFetching } = useQuery(
    [
      "campaignTopChangesMostActiveTopics",
      campaignId,
      selectedDateRange,
      selectedSplitBy,
      selectedTopics.data.map((a) => a.value).join(),
    ],
    () => {
      if (campaignId) {
        return getMostActiveTopicsTimeseries({
          campaignId,
          signals: selectedTopics.data,
          dateRange: selectedDateRange,
          splitBy: selectedSplitBy,
        });
      }
    },
    {
      enabled: !isLoading,
    }
  );

  const { start, end } = getDatesFromDateRange(
    selectedDateRange,
    new Date(meta?.campaignStartDate ?? ""),
    new Date(meta?.campaignEndDate ?? "")
  );

  const dates = useMemo(() => {
    return {
      start: start.toISO(),
      end: end.toISO(),
    };
  }, [start, end]);

  const chart = useExportableChart({
    title,
    campaignId: Number(campaignId),
    dates,
    chart: (
      <Timeseries
        noDataMessage={
          !isPopulatedArray(selectedTopics) ? "No data found." : undefined
        }
        stretch={true}
        dataMainKey={"label"}
        isLoading={isFetching || isLoading}
        data={data?.data ?? []}
        dataKeyLabels={{ value: "Intent Signals" }}
        timeseries={data?.timeseries ?? []}
      />
    ),
  });

  return (
    <ViewContainer
      withBackground
      expandable={true}
      title={title}
      actionItems={
        <Flex>
          <ViewSelector
            items={[
              {
                value: ViewEnum.Table,
                onClick: setSelectedView,
                label: "Table",
                icon: <AiOutlineTable />,
              },
              {
                value: ViewEnum.TimeSeries,
                onClick: setSelectedView,
                label: "Timeseries",
                icon: <AiOutlineLineChart />,
              },
            ]}
            selected={selectedView}
          />

          <Box ml={2}>
            <ChartExport
              dates={dates}
              campaignId={Number(campaignId)}
              title={chart.title}
              onExportPNG={chart.downloadAsPng}
              data={timeseriesToCsvData(data, "label", { value: "Signals" })}
            />
          </Box>
        </Flex>
      }
    >
      <Filters />

      {selectedView === ViewEnum.Table && (
        <TrendingTopicsTable isLoading={isFetching || isLoading} data={data} />
      )}

      {selectedView === ViewEnum.TimeSeries && (
        <Flex flexGrow={1}>{chart.component}</Flex>
      )}

      {chart.exportComponent}
    </ViewContainer>
  );
};

export { TrendingTopics };
