import { TalToolFileRO } from "@intentsify/dto";
import { TalToolFileState, TalToolsTypes } from "@intentsify/types";
import { useCallback } from "react";
import { TalToolsFiles } from "../../TalToolsFiles/TalToolsFiles";
import { BusinessDataAppendFileTooltip } from "./BusinessDataAppendFileTooltip";

type BusinessDataAppendFilesProps = {
  files: TalToolFileRO[];
  onFileDelete: (fileName: string) => void;
};

export const BusinessDataAppendFiles = ({
  files,
  onFileDelete,
}: BusinessDataAppendFilesProps) => {
  const tooltipLabelRender = useCallback((file: TalToolFileRO) => {
    if (file.state === TalToolFileState.COMPLETED && file.details) {
      return {
        label: "",
        content: <BusinessDataAppendFileTooltip details={file.details} />,
      };
    }
    return null;
  }, []);

  return (
    <TalToolsFiles
      talToolsType={TalToolsTypes.BUSINESS_DATA_APPEND}
      files={files}
      onFileDelete={onFileDelete}
      tooltipLabelRender={tooltipLabelRender}
    />
  );
};
