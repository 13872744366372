import { Center, Text } from "@chakra-ui/layout";
import { TargetPersonaDynamic1 } from "@intentsify/types";
import { Table } from "components";
import { useMemo } from "react";
import { useDynamic1 } from "../BuyingGroup.requests";
import { columns } from "./Dynamic1Table.utils";

type Dynamic1TableProps = {
  campaignId: number;
  accountId: number;
  selectedPersonas: string[];
};

const Dynamic1Table = ({
  campaignId,
  accountId,
  selectedPersonas,
}: Dynamic1TableProps) => {
  const { data: dynamic1Data = [] } = useDynamic1({
    accountId,
    campaignId,
  });

  const filteredDynamic1 = useMemo(() => {
    if (selectedPersonas.length > 0) {
      return dynamic1Data.filter((d1) =>
        selectedPersonas.includes(`${d1.jobLevel}, ${d1.jobFunction}`)
      );
    }
    return dynamic1Data;
  }, [dynamic1Data, selectedPersonas]);

  if (filteredDynamic1.length === 0) {
    return (
      <Center p={8}>
        <Text fontWeight={"semibold"}>No keywords found</Text>
      </Center>
    );
  }

  return (
    <Table<TargetPersonaDynamic1>
      stretch
      maxWidth="100%"
      columns={columns}
      data={filteredDynamic1}
      defaultSort={[{ id: "name", desc: true }]}
      isScrollable={false}
      isControlledByAPI={false}
    />
  );
};

export { Dynamic1Table };
