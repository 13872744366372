import { Flex, useColorModeValue } from "@chakra-ui/react";
import { Option } from "@intentsify/types";
import { Grid, Modal, NoData, ScreenBar, ViewContainer } from "components";
import { useNavigate, useParams } from "react-router";
import { useUnmount, useUpdateEffect } from "react-use";
import { useRecoilState } from "recoil";
import {
  CampaignActionsItems,
  CampaignMetaHeader,
  CampaignSelector,
  WithIntentDataAvailable,
  useCampaignMeta,
  useCampaignSelectorSelectedCampaign,
} from "shared/components";
import { useScreen } from "utils";
import { TrendingAccountsScreenDefinition } from "./CampaignsTrendingDomains.definition";
import { useResetState } from "./CampaignsTrendingDomains.hooks";
import { accountDetailsAtom } from "./CampaignsTrendingDomains.state";
import { TopPerformingDomains, TopTrendingAccounts } from "./components";
import { SingleAccountDetails } from "./components/SingleAccountDetails/SingleAcountDetails";

const CampaignsTrendingDomains = () => {
  useScreen(TrendingAccountsScreenDefinition);
  const campaignId = Number(useParams<"campaignId">().campaignId);
  const navigate = useNavigate();
  const resetState = useResetState();

  const [accountDetails, setAccountDetails] =
    useRecoilState(accountDetailsAtom);

  const singleAccountDetailsModalBackgroundColor = useColorModeValue(
    "white",
    "brand.400"
  );

  useUnmount(() => {
    resetState();
  });

  useUpdateEffect(() => {
    resetState();
  }, [campaignId]);

  const { selected, setSelected } = useCampaignSelectorSelectedCampaign(
    Number(campaignId)
  );

  const { campaignMetaIsLoading, campaignMeta } = useCampaignMeta({
    campaignId,
  });

  const onCampaignChange = (c: Option<number> | undefined) => {
    setSelected(c);

    if (!c) {
      navigate(
        TrendingAccountsScreenDefinition.navigate({ campaignId: undefined })
      );
    } else {
      resetState();
      navigate(
        TrendingAccountsScreenDefinition.navigate({
          campaignId: Number(c?.value),
        })
      );
    }
  };

  return (
    <>
      <ScreenBar
        filters={[
          {
            label: "Intent Models",
            width: "100%",
            node: (
              <CampaignSelector
                selected={selected}
                onChange={onCampaignChange}
              />
            ),
          },
        ]}
      />

      {!campaignId && (
        <ViewContainer>
          <NoData message="Please select an intent model" />
        </ViewContainer>
      )}

      {campaignId && (
        <>
          <Flex justifyContent="space-between" p={4} alignItems="top">
            <CampaignMetaHeader />
            <CampaignActionsItems
              isLoading={campaignMetaIsLoading}
              campaignId={Number(campaignId)}
              companyId={campaignMeta?.companyId}
              campaignCreatedDate={campaignMeta?.campaignCreateDate}
              hasBeenMappedToConvertr={Boolean(campaignMeta?.bookedLeads)}
              hasLeads={Boolean(campaignMeta?.numberOfLeads)}
              hasSpotlight={campaignMeta?.hasSpotlight}
              campaignStatusId={campaignMeta?.campaignStatusId}
              numberOfVisibleItems={1}
              showCampaignDetailsAction={true}
              hasRelatedProgrammaticCampaigns={Boolean(
                campaignMeta?.hasRelatedProgrammaticCampaigns
              )}
              hasPixel={Boolean(campaignMeta?.hasPixel)}
            />
          </Flex>
          <Grid>
            <WithIntentDataAvailable>
              <TopPerformingDomains />
              <TopTrendingAccounts />
            </WithIntentDataAvailable>
          </Grid>

          {accountDetails && (
            <Modal
              backgroundColor={singleAccountDetailsModalBackgroundColor}
              body={<SingleAccountDetails />}
              size="viewContainer"
              title={accountDetails.account}
              isOpen={Boolean(accountDetails)}
              onClose={() => setAccountDetails(undefined)}
            ></Modal>
          )}
        </>
      )}
    </>
  );
};

export { CampaignsTrendingDomains };
