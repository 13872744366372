import { css } from "@emotion/react";

import { Box, Flex, Stack, useToast } from "@chakra-ui/react";
import {
  HasAccess,
  useHasAccessTo,
} from "@intentsify/authorization/dist/react";
import {
  AudienceSegmentDefinition,
  BuyingGroupContactsAccounts,
  CampaignDetails,
  CampaignMeta,
  DateRangeFormat,
  FetchDataParams,
  defaultAudienceSegmentDefinition,
} from "@intentsify/types";
import { formatDateRange, getExportFileName } from "@intentsify/utils";
import { Row } from "@tanstack/react-table";
import { Endpoints } from "api/api.endpoints";
import {
  Button,
  InfiniteScrollTable,
  Loader,
  SomethingWrong,
} from "components";
import { PaginationType } from "components/Table/Table/Table.types";
import { DateTime } from "luxon";
import { useState } from "react";
import { RiDownloadLine } from "react-icons/ri";
import { useUser } from "store/store.hooks";
import { useTrackCampaignFileDownloaded } from "tracking/useTrackCampaignFileDownloaded";
import {
  combineParamsAndFilters,
  paramsToQueryUrl,
  useDownloadFile,
} from "utils";
import { useCampaignBuyingGroup } from "../../../../../shared/components/campaign/Campaign.state";
import { listPaginatedCampaignDetails } from "../CampaignDetails.requests";
import { FiltersContainer } from "../components/FiltersContainer";
import { useColumns } from "./CampaignDetailsTable.columns";
import { useCampaignSignalsAndFilters } from "./CampaignDetailsTable.hooks";
import { FetchDataParamsCampaignsDetails } from "./CampaignDetailsTable.types";
import { AudienceSegments } from "./components/AudienceSegments/AudienceSegments";
import { ExpandedRow } from "./components/SignalsTabs/components/ExpandedRow/ExpandedRow";

const defaultListPaginatedCampaignDetailsParams = {
  page: 1,
  per_page: 25,
  order_by: "buyerResearchStage",
  order: "ASC",
} as FetchDataParamsCampaignsDetails<keyof CampaignDetails>;

type CampaignDetailsTableProps = {
  campaignId: number;
  weekNumber: number;
  yearNumber: number;
  campaignMeta: CampaignMeta | undefined;
};

const CampaignDetailsTable = ({
  campaignId,
  weekNumber,
  yearNumber,
  campaignMeta,
}: CampaignDetailsTableProps) => {
  const { download } = useDownloadFile();
  const hasAccessToDetailsDownload = useHasAccessTo("campaign.downloadDetails");
  const hasAccessToBuyingGroup = useHasAccessTo("buyingGroup");

  const { data: buyingGroupSettings } = useCampaignBuyingGroup(
    campaignId,
    hasAccessToBuyingGroup
  );

  const isBuyingGroupDownloadEnabled =
    campaignMeta?.hasBuyingGroup &&
    buyingGroupSettings &&
    buyingGroupSettings.buyingGroupContactsAccounts !==
      BuyingGroupContactsAccounts.NONE;

  const user = useUser();

  const [persistedCampaignDetailsFilters, setPersistedCampaignDetailsFilters] =
    useState<AudienceSegmentDefinition>(defaultAudienceSegmentDefinition);

  const didUserUseFilters =
    JSON.stringify(defaultAudienceSegmentDefinition) !==
    JSON.stringify(persistedCampaignDetailsFilters);

  const { error, signals, filters } = useCampaignSignalsAndFilters(
    campaignId,
    weekNumber,
    yearNumber
  );

  const toast = useToast();
  const [resultsCount, setResultsCount] = useState<number | undefined>(
    undefined
  );
  const [isLoadingResults, setIsLoadingResults] = useState(true);

  const columns = useColumns({
    user,
    campaignMeta,
    campaignId,
    weekNumber,
    yearNumber,
  });

  const useRenderExpandableRowComponent = (row: Row<CampaignDetails>) => {
    if (!signals.data || !row.original?.domainId) {
      return;
    }

    return (
      <ExpandedRow
        isExpanded={row.getIsExpanded()}
        signals={signals.data}
        campaignId={campaignId}
        domainId={row.original?.domainId}
        weekNumber={weekNumber}
        yearNumber={yearNumber}
        buyerResearchStage={row.original?.buyerResearchStage}
      />
    );
  };

  const trackCampaignFileDownloaded = useTrackCampaignFileDownloaded();

  if (error) {
    toast({
      title: "Cannot get campaign details",
      status: "error",
    });

    return <SomethingWrong />;
  }

  const baseDate = DateTime.fromObject({
    weekNumber: weekNumber,
    weekYear: yearNumber,
  });

  const downloadSelectionFilename = getExportFileName({
    prefix: `intent_model_week_${formatDateRange(
      {
        start: baseDate.startOf("week").toISODate(),
        end: baseDate.endOf("week").toISODate(),
      },
      DateRangeFormat.StartEndDateMonthDash
    )}`,
    data: {
      id: campaignId,
    },
  });

  const handleDownloadSelection = async () => {
    await download({
      url: `${Endpoints.CampaignDetails.Get.DownloadSelection(
        campaignId
      )}?${paramsToQueryUrl({
        params: combineParamsAndFilters(
          defaultListPaginatedCampaignDetailsParams,
          {
            ...persistedCampaignDetailsFilters,
            weekNumber,
            yearNumber,
          }
        ),
        includeFirstAmpersand: false,
      })}`,
      fileName: downloadSelectionFilename,
      fileExtension: "zip",
    });

    trackCampaignFileDownloaded({
      type: "campaign details csv",
      campaignIds: [campaignId],
    });
  };

  const handleDownloadBuyingGroup = async () => {
    const contactsType = String(
      buyingGroupSettings?.buyingGroupContactsAccounts ??
        BuyingGroupContactsAccounts.NONE
    );
    await download({
      url: `${Endpoints.CampaignDetails.Get.DownloadBuyingGroup(
        campaignId
      )}?${paramsToQueryUrl({
        params: combineParamsAndFilters(
          defaultListPaginatedCampaignDetailsParams,
          {
            ...persistedCampaignDetailsFilters,
            weekNumber,
            yearNumber,
          }
        ),
        includeFirstAmpersand: false,
      })}&contactAccountsType=${contactsType}`,
      fileName: `${campaignId}_buying_group`,
      fileExtension: "zip",
    });
    trackCampaignFileDownloaded({
      type: "campaign details with buying group",
      campaignIds: [campaignId],
    });
  };

  const fetchData = (params?: FetchDataParams<keyof CampaignDetails>) => {
    return listPaginatedCampaignDetails(campaignId)(
      combineParamsAndFilters(
        {
          ...defaultListPaginatedCampaignDetailsParams,
          order:
            params?.order || defaultListPaginatedCampaignDetailsParams.order,
          order_by:
            params?.order_by ||
            defaultListPaginatedCampaignDetailsParams.order_by,
          page: params?.page || defaultListPaginatedCampaignDetailsParams.page,
          per_page:
            params?.per_page ||
            defaultListPaginatedCampaignDetailsParams.per_page,
        },
        {
          ...persistedCampaignDetailsFilters,
          weekNumber,
          yearNumber,
        }
      )
    );
  };

  // Fixes filters overlow issue
  const overflowVisible = css`
    .chakra-collapse {
      overflow: visible !important;
    }
  `;

  return (
    <>
      <Stack spacing={"4"}>
        <Box rounded="md" px={4} css={overflowVisible} zIndex={1}>
          <FiltersContainer
            resultsCount={resultsCount}
            isLoadingResults={isLoadingResults}
            areFiltersApplied={didUserUseFilters}
            title="Audience Filters & Segments"
          >
            {filters?.data ? (
              <AudienceSegments
                filtersOptions={filters.data}
                companyId={Number(campaignMeta?.companyId)}
                onApplyAudienceSegment={(audienceSegment) => {
                  setPersistedCampaignDetailsFilters({
                    ...audienceSegment,
                  });
                }}
              />
            ) : (
              <Loader minHeight={"100px"} />
            )}
          </FiltersContainer>
        </Box>
        <Box px={4}>
          {hasAccessToDetailsDownload && (
            <Flex mb={4}>
              <Button
                variant="ghost"
                rightIcon={<RiDownloadLine />}
                size="sm"
                onClick={() => {
                  handleDownloadSelection();
                }}
                isDisabled={isLoadingResults}
              >
                Download CSV
              </Button>
              {isBuyingGroupDownloadEnabled && (
                <HasAccess to="buyingGroup">
                  <Button
                    variant="primary-teal"
                    rightIcon={<RiDownloadLine />}
                    size="sm"
                    onClick={() => {
                      handleDownloadBuyingGroup();
                    }}
                    isDisabled={isLoadingResults}
                  >
                    Request to Download Buying Group CSV
                  </Button>
                </HasAccess>
              )}
            </Flex>
          )}

          <InfiniteScrollTable<CampaignDetails, PaginationType.LIMIT_OFFSET>
            columns={columns}
            fetchData={fetchData}
            height={900}
            name={`campaginDetails_${campaignId}`}
            estimatedRowHeight={100}
            renderExpandableRowComponent={useRenderExpandableRowComponent}
            defaultSort={[
              {
                id: "buyerResearchStage",
                desc: false,
              },
            ]}
            paginationType={PaginationType.LIMIT_OFFSET}
            filters={{
              ...persistedCampaignDetailsFilters,
              weekNumber,
              yearNumber,
            }}
            disableVirtualization={true}
            itemsPerPage={defaultListPaginatedCampaignDetailsParams.per_page}
            noDataMessage={
              !didUserUseFilters
                ? "Data is processing."
                : "No results. Please adjust filters."
            }
            onLoadingStateChange={(isLoading, data) => {
              setIsLoadingResults(isLoading);
              setResultsCount(data?.pages[0]?.meta?.total_items);
            }}
          />
        </Box>
      </Stack>
    </>
  );
};

export { CampaignDetailsTable };
