import { Box } from "@chakra-ui/react";
import { useGetHasAccessTo } from "@intentsify/authorization/dist/react";
import { TalToolsTypes } from "@intentsify/types";
import { isDefined } from "@intentsify/utils";
import { ButtonGroup, ViewContainer } from "components";
import { useSearchParams } from "react-router-dom";
import { useScreen } from "utils";
import { TalPreparationToolsDefinition } from "./TalPreparationTools.definition";
import { BusinessDataAppend } from "./components/BusinessDataAppend/BusinessDataAppend";
import { CompanyNameToDomain } from "./components/CompanyNameToDomain/CompanyNameToDomain";
import { SmarTAL } from "./components/SmarTAL/SmarTAL";

const useTabs = () => {
  const hasAccessTo = useGetHasAccessTo();

  const tabs = [
    hasAccessTo("talPreparationTools.companyNameToDomain")
      ? {
          label: "Company Name to Domain",
          value: TalToolsTypes.COMPANY_NAME_TO_DOMAIN,
        }
      : undefined,
    hasAccessTo("talPreparationTools.businessDataAppend")
      ? {
          label: "Business Data Append",
          value: TalToolsTypes.BUSINESS_DATA_APPEND,
        }
      : undefined,
    hasAccessTo("talPreparationTools.smarTAL")
      ? {
          label: "SmartTAL",
          value: TalToolsTypes.SMARTAL,
        }
      : undefined,
  ].filter(isDefined);

  return tabs;
};

const TAB_PARAM = "tab";

export const TalPreparationTools = () => {
  useScreen(TalPreparationToolsDefinition);

  const tabs = useTabs();
  const [searchParams, setSearchParams] = useSearchParams();
  const tabParam = searchParams.get(TAB_PARAM) ?? "";
  const selectedTab = Object.values(TalToolsTypes).includes(
    tabParam as TalToolsTypes
  )
    ? tabParam
    : tabs[0]?.value ?? (undefined as TalToolsTypes | undefined);

  return (
    <ViewContainer>
      <ButtonGroup
        selectedValue={selectedTab}
        setSelectedValue={(value) => setSearchParams({ [TAB_PARAM]: value })}
        items={tabs}
      />
      <Box>
        {selectedTab === TalToolsTypes.BUSINESS_DATA_APPEND && (
          <BusinessDataAppend />
        )}
        {selectedTab === TalToolsTypes.COMPANY_NAME_TO_DOMAIN && (
          <CompanyNameToDomain />
        )}
        {selectedTab === TalToolsTypes.SMARTAL && <SmarTAL />}
      </Box>
    </ViewContainer>
  );
};
