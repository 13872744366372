import { Box } from "@chakra-ui/react";
import { firmoOptions } from "./BusinessDataAppend.options";

type BusinessDataAppendFileTooltipProps = {
  details: {
    firmos: string[];
    technos: string[];
    business_events_categories: string[];
    business_events_per_category: number;
  };
};

export const BusinessDataAppendFileTooltip = ({
  details,
}: BusinessDataAppendFileTooltipProps) => {
  return (
    <>
      {Boolean(details.firmos?.length) && (
        <Box>
          Firmo:{" "}
          {details.firmos
            .map(
              (firmo) =>
                firmoOptions.find((option) => option.value === firmo)?.label
            )
            .filter(Boolean)
            .join(", ")}
        </Box>
      )}
      {Boolean(details.technos?.length) && (
        <Box>Techno: {details.technos.filter(Boolean).join(", ")}</Box>
      )}
    </>
  );
};
