import { Box, Flex } from "@chakra-ui/layout";
import {
  Alert,
  Heading,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { TalToolsTypes } from "@intentsify/types";
import { isBasicValidDomain, pluralize } from "@intentsify/utils";
import {
  ValueValidationError,
  buildZodCsvValidator,
} from "@intentsify/utils/csvStream/browser";
import { Upload } from "components";
import { z } from "zod";
import { useTalToolDropzone } from "../../hooks/useTalToolDropzone";
import { BusinessDataAppendForm } from "./components/BusinessDataAppendForm";

const csvValidator = () =>
  buildZodCsvValidator(
    z.object({
      Domain: z
        .string()
        .refine(
          (value) => isBasicValidDomain(value),
          "Invalid domain"
        ) as unknown as z.ZodString,
    }),
    {
      quoteMode: "none",
      caseInsensitiveHeader: true,
      validationMode: "validateAll",
    }
  );

const resumeUploadConfirm = {
  title: "Business Data Append uploading",
  description: `We detected that uploading Business Data Append was interrupted. Would you like to resume uploading?`,
  confirmText: "Continue",
};

const csvErrorConfirmBuilder = ({
  hasMoreErrorsThanTheLimit,
  totalErrorsCount,
  errorSamples,
}: {
  hasMoreErrorsThanTheLimit: boolean;
  totalErrorsCount: number;
  errorSamples: ValueValidationError[];
}) => ({
  title: "Business Data Append Validation",
  description: (
    <>
      <Box>
        We detected{" "}
        {pluralize(totalErrorsCount, "1 error", `${totalErrorsCount} errors`)}{" "}
        in CSV you uploaded. You can ignore and continue, however we may not be
        able to append data with errors.
      </Box>
      <Box mt={2}>
        {hasMoreErrorsThanTheLimit ? "Top " : ""}
        {pluralize(totalErrorsCount, "1 error", `${totalErrorsCount} errors`)}:
      </Box>
      <Box maxH={150} overflowY="auto">
        <Table size="sm">
          <Thead position="sticky">
            <Tr>
              <Th>CSV Row</Th>
              <Th>Value</Th>
            </Tr>
          </Thead>
          <Tbody>
            {errorSamples.map((err, index) => (
              <Tr key={index}>
                <Td>{err.rowPos}</Td>
                <Td px={0}>
                  {err.value || <Text opacity={0.6}>(NO VALUE)</Text>}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </>
  ),
  confirmText: "Ignore & Continue",
});

export const BusinessDataAppend = () => {
  const {
    getRootProps,
    getInputProps,
    isTooManyFiles,
    lastUploadedFile,
    validationProgress,
  } = useTalToolDropzone(TalToolsTypes.BUSINESS_DATA_APPEND, {
    resumeUploadConfirm,
    csvErrorConfirmBuilder,
    csvValidator,
  });

  return (
    <Flex direction="column" gap="4">
      <Box>
        <Heading as="h2" size="sm" my="4">
          Instructions
        </Heading>
        <Box as="ol" fontSize="xs" ml="8">
          <li>
            Upload a CSV file with one column containing domains and a header
            row with single value "Domain".
          </li>

          <li>
            Select from the drop downs either/or firmographics, technographics,
            business events you need appended.
          </li>

          <li>Click "Process domains".</li>
          <li>
            You'll receive an email when the list has been processed containing
            the link to download.
          </li>
        </Box>
        {isTooManyFiles && (
          <Box mb={2} mt={4}>
            <Alert status="error">
              Too many files uploaded. Please remove some files before
              uploading, maximum 25 files allowed.
            </Alert>
          </Box>
        )}
      </Box>

      <Box>
        <Upload
          getInputProps={getInputProps}
          getRootProps={getRootProps}
          subHeader={"CSV with domains."}
          validationProgress={validationProgress}
        />
      </Box>

      <BusinessDataAppendForm lastUploadedFile={lastUploadedFile} />
    </Flex>
  );
};
